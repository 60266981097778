<template>
    <div class="flex flex-col w-full h-auto mb-12">
        <!-- Header -->
        <div class="flex w-full h-auto justify-center items-center">
            <span class="Helvetica text-2xl font-bold text-green-500  justify-center normal-case">{{$t('shipping_informations')}}</span>
        </div>
        <div v-if="currentUser.type=='Admin' || currentUser.type=='CEO' || currentUser.type=='Livreur'" class="flex py-2 w-full h-auto justify-center items-center">
            <div class="flex"> 
                <a class="mr-5 tracking-wide text-green-700 font-bold rounded border-2 border-yellow-400 hover:border-green-700 hover:bg-green-700 hover:text-white shadow-md py-2 px-6 inline-flex items-center transition duration-300 cursor-pointer" @click="OpenClosePopupModal('open','postponed')">{{$t('postponed')}}</a>
                <a class="tracking-wide text-green-700 font-bold rounded border-2 border-yellow-400 hover:border-green-700 hover:bg-green-700 hover:text-white shadow-md py-2 px-6 inline-flex items-center transition duration-300 cursor-pointer" @click="OpenClosePopupModal('open','unreachable')">{{$t('unreachable')}}</a>
             </div>   
        </div>
        <!-- ******************************** -->
        <div class="px-6">
            <div class=" w-full">
                    <div class="flex space-x-2 w-full shadow-xs mt-1 mb-1 rounded-lg">
                        <div class="flex w-full justify-between items-center h-auto p-6 ">
                            <div class="flex w-full">
                                 <span class="rounded-full h-12 w-12 bg-gray-200">
                                    <i class="material-icons p-3 text-green-500 ">local_mall</i>
                                </span>
                                <div class="ml-3 w-full p-2">
                                    <div class="w-full flex text-base font-semibold text-gray-600">
                                        <p class="w-1/2">{{$t('shipping_informations')}}</p>
                                    </div>
                                    <div class="bg-white rounded-lg p-6 pb-0">
                                        <div class="grid lg:grid-cols-2 gap-6">
                                            <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">                    
                                                <p>
                                                    <label for="customerAddress" class="bg-white text-gray-700 px-1" >{{$t('address_shipping')}}<span :class="$colors.required">*</span></label>
                                                </p>
                                                </div>
                                                <p>
                                                <textarea id="customerAddress" type="text" v-model="item.address"
                                                    class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"></textarea>
                                                </p>
                                            </div> 
                                            <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                                    <p>
                                                        <label for="shippdate" class="bg-white text-gray-700 px-1">{{$t('shipping_date')}}
                                                            <span :class="$colors.required">*</span></label>
                                                    </p>
                                                </div>
                                                <p>
                                                    <input id="shippdate" :disabled="!['CEO','StockManager','OperationsManager'].includes(currentUser.type)"  type="date"  v-model="item.date" class="py-2 text-xs px-2 outline-none block h-full w-full" />
                                                </p>
                                           </div>
                                            <div v-if="item.country && item.country != 'MA'" class="border h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                                    <p>
                                                    <label for="category" class="bg-white text-gray-700 px-1">{{$t('zone_shipping')}} <span :class="$colors.required">*</span></label>
                                                    </p>
                                                </div>
                                                <p>
                                                 <v-select v-bind:class="{ disabled: currentUser.type=='Courier' }"  label="name" v-model="item.zone" :options="zones" ></v-select>
                                                </p>
                                            </div>
                                            <div  class="border h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                                    <p>
                                                    <label for="country" class="bg-white text-gray-700 px-1">{{$t('country')}} <span :class="$colors.required">*</span></label>
                                                    </p>
                                                </div>
                                                <p>
                                                 <v-select
                                                    v-model="item.country"
                                                    :options="dataCountry"
                                                    v-bind:class="{ disabled: true }"
                                                    label="name"
                                                    >
                                                    <template slot="option" slot-scope="option">
                                                        <img
                                                        class="flag-img"
                                                        :src="$f.getCountryCode(option.code)"
                                                        />
                                                        {{ option.name }}
                                                    </template>
                                            </v-select>
                                                </p>
                                            </div>
                                            <div class="border h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                                    <p>
                                                    <label for="courier" class="bg-white text-gray-700 px-1">{{$t('courier')}} <span :class="$colors.required">*</span></label>
                                                    </p>
                                                </div>
                                                <p>
                                                <v-select v-bind:class="{ disabled: currentUser.type=='Courier' }"  label="fullName" @search="searchCouriers($event,'hh')" v-model="item.courier" :options="couriers" ></v-select>
                                                </p>
                                            </div>
                                            <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                                    <p>
                                                        <label for="city" class="bg-white text-gray-700 px-1">{{$t('city')}} <span :class="$colors.required">*</span></label>
                                                    </p>
                                                </div>
                                                <p>
                                                    <input id="city" disabled type="text" v-model="item.order.customer.city" class="py-2 text-xs px-2 outline-none block h-full w-full" />
                                                </p>
                                            </div>
                                            <div v-if="item.order.customer.city && feesShipp && item.country == 'MA'" class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                                <p>
                                                <label
                                                    for="Frais"
                                                    disabled
                                                    class="bg-white text-gray-700 px-1"
                                                    >{{$t('shipping_cost')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup>
                                                </label>
                                                </p>
                                            </div>
                                            <p>
                                                <input id="Frais" disabled :value="feesShipp" type="text" class="py-2 text-xs px-2 outline-none block h-full w-full"/>
                                            </p>
                                            </div>
                                            <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                                    <p>
                                                        <label for="status" class="bg-white text-gray-700 px-1">{{$t('status')}} <span :class="$colors.required">*</span>
                                                        </label>
                                                    </p>
                                                </div>
                                                <p>
                                                    <select @change="onChangeStatus($event)" :disabled="(item.status==='processed' || item.status==='return' || item.status==='rembourser')" :style="colorStatus(item.status)"  v-model="item.status" class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white">
                                                        <option class="bg-white" selected :value="null"> {{$t('status')}} </option>
                                                        <option class="bg-white text-gray-600" v-for="(value, index) in datatatus" :key="index" :value="value">
                                                            {{ value }}
                                                        </option>
                                                    </select>
                                                </p>
                                            </div>
                                            
                                            <div v-if="item.type!='normal'" class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                                    <p>
                                                        <label for="type" class="bg-white text-gray-700 px-1">{{$t('type')}} <span :class="$colors.required">*</span>
                                                        </label>
                                                    </p>
                                                </div>
                                                <p>
                                                    <select  v-model="item.type" class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white">
                                                        <option class="bg-white" selected :value="null"> {{$t('type')}} </option>
                                                        <option class="bg-white text-gray-600" v-for="(value, index) in typesShipp" :key="index" :value="value">
                                                            {{ value }}
                                                        </option>
                                                    </select>
                                                </p>
                                            </div>
                                             <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">                    
                                                <p>
                                                    <label for="customerAddress" class="bg-white text-gray-700 px-1" >{{$t('comment_order')}}<span :class="$colors.required">*</span></label>
                                                </p>
                                                </div>
                                                <p>
                                                <textarea id="customerAddress" type="text" v-model="item.order.comment"
                                                    class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"></textarea>
                                                </p>
                                            </div>
                                             <div v-if="item.status=='cancelled' || item.status=='refused'" class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">                    
                                                <p>
                                                    <label for="customerAddress" class="bg-white text-gray-700 px-1" >{{$t('cancelatation_comment_shipping')}}</label>
                                                </p>
                                                </div>
                                                <p>
                                                <textarea id="customerAddress" type="text" v-model="item.comment"
                                                    class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"></textarea>
                                                </p>
                                            </div>
                                        </div>
                                    </div>     
                                </div>    
                            </div>    
                        </div>
                    </div>
                     <div class="flex  mt-4 shadow-xs rounded-lg">
                        <div class="flex justify-between pb-0 w-full h-auto p-4 rounded-lg border-gray-100" >
                            <div class="flex w-full">
                            <span class="rounded-full h-12 w-12 bg-gray-100">
                                <i class="material-icons p-3 text-green-400 ">local_mall</i>
                            </span>
                            <div class="ml-1 w-full p-2">
                                <div class="flex items-center ">
                                <div class="text-base font-semibold text-gray-700">  {{$t('products_order')}}  </div>
                                <button v-if="item.status!='processed' && ['Admin','CEO'].includes(currentUser.type)" @click="ChangeQuantityOrder(item)" class="btn ml-auto btn-primary mr-2 mb-2 bg-blue-700 text-white py-2 px-4 items-center focus:outline-none"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide block mx-auto"><path d="M17 2l4 4-4 4"></path><path d="M3 11v-1a4 4 0 014-4h14"></path><path d="M7 22l-4-4 4-4"></path><path d="M21 13v1a4 4 0 01-4 4H3"></path></svg> Change quantity Or TotalPrice </button>
                                </div>
                                <div class="w-full mt-4">
                                <table class="min-w-full leading-normal">
                                    <thead>
                                    <tr> <th class="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider">{{$t('image')}}</th>
                                        <th class="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider">{{$t('product')}}</th>
                                        <th class="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider">{{$t('quantity')}}</th>
                                        <th class="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider">{{$t('price')}}</th>
                                    </tr>
                                    </thead>
                                    <tbody
                                    v-for="(it, index) in item.order.details" :key="it._id">
                                    <tr :class="index % 2 == 0 ? 'bg-gray-100' : ''">
                                        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                        <span class="flex text-gray-700 capitalize whitespace-no-wrap"><span class="mt-1"><img :src="it.product.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="h-30 rounded-md mx-auto"></span></span>
                                        </td>
                                        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                        <span class="flex text-gray-700 capitalize whitespace-no-wrap"><span class="mt-1">{{ it.product.name }}</span></span>
                                        </td>
                                        <td
                                        class="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                                        >
                                        <p class="text-gray-700 ml-6 whitespace-no-wrap">
                                            <span class="font-semibold"
                                            >{{ it.quantity }}
                                            </span>
                                        </p>
                                        </td>
                                        <td
                                        class="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                                        >
                                        <span
                                            class="justify-center text-xs text-center pl-2 pr-2 py-1 rounded-full text-gray-700"
                                        >
                                            <span
                                            class="text-gray-800 font-semibold text-xs ml-1"
                                            >
                                            {{ it.unitPrice }}
                                            </span>
                                        <sup v-if="idWharhouse" class="uppercase">{{idWharhouse.currency }}</sup>
                                        </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tbody><tr><td colspan="5" class="px-6 text-right py-2 whitespace-no-wrap border-gray-200"><div class="text-xs leading-5 capitalize px-2 rounded-full font-bold text-gray-900">  {{$t('total_price')}}: {{item.order.totalPrice}} <sup v-if="idWharhouse" class="uppercase">{{idWharhouse.currency }}</sup></div></td></tr></tbody>
                                </table>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    <div class="tab-cc w-full s-sidebarwidget s-sidebarwidget__yellow s-anchors s-anchors__grayscale">
                            <div class="d-block p-0 m-0">
                                <div class="s-sidebarwidget--header flex s-sidebarwidget__small-bold-text fc-light d:fc-black-900 bb bbw1"><svg xmlns="http://www.w3.org/2000/svg" class="fill-current mr-2"  width="20" height="20" viewBox="0 0 24 24"><path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/></svg> Change in order of cases</div>
                                <div class="s-sidebarwidget--item">
                                    
                              <table class="w-full">
                                    <tbody>
                                        <tr><td><b>To prepare</b></td><td><span><svg class="fill-current" width="24" height="24"  xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>OutOfStock</b></td></tr>
                                        <tr><td><b>OutOfStock</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>To prepare</b></td></tr>
                                        </tbody>
                                </table>
                                <hr class="hr-grey">
                                <table class="w-full">
                                    <tbody>
                                        <tr><td><b>To prepare</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>Prepared</b></td></tr>
                                        <tr><td><b>(Prepared,Reprogrammer)</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>Shipped</b></td></tr>
                                        <tr><td><b>Shipped</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>(Reprogrammer,Delivered)</b></td></tr>
                                        <tr><td><b>Delivered</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>Paid</b></td></tr>
                                        </tbody>
                                    </table>
                                    <hr class="hr-grey">
                                    <table class="w-full">
                                    <tbody>
                                        <tr><td><b>(Shipped,Reprogrammer,Delivered)</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>Cancelled</b></td></tr>
                                        <tr><td><b>(Shipped,Reprogrammer,Delivered)</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>Refused</b></td></tr>
                                        <tr><td><b>(Cancelled,Refused,Paid)</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>Return</b></td></tr>
                                        </tbody>
                                    </table>
                                  </div>    
                            </div>
                    </div>
               </div>
        </div>
        <!-- ***************************************-->
         <!--*******************  Order refund ***************************************-->
          <!--  <div v-if="item.status=='return' && (currentUser.type=='Admin' || currentUser.type=='CEO' || currentUser.type=='StockManager')" class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
                    <div class="flex w-full mt-1">
                        <span class="rounded-full h-12 w-12 bg-gray-200">
                            <i class="material-icons p-3 text-green-400 ">replay</i>
                        </span>

                        <div class="ml-5 w-full pb-4">
                            <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                                 {{$t('order_refunded_products')}}
                            </div>

                            <div class="w-full space-y-4">
                                <div class="w-full">
                                    <table style="display: inline-table;"
                                        class="w-full flex flex-row flex-no-wrap sm:bg-white rounded-md overflow-hidden sm:shadow-xs">
                                        <thead class="text-white">
                                            <tr
                                                class="bg-green-400  flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                                <th class="p-2 justify-center">{{$t('products')}}</th>
                                                <th class="p-2 justify-center"> {{$t('quantity')}}</th>
                                                <th class="p-2 justify-center"> {{$t('quantity_returned')}}</th>                                            
                                            </tr>
                                        </thead>
                                        <tbody class="flex-1 sm:flex-none" v-for="it in item.order.details" :key="it._id">
                                            <tr class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                                                <td
                                                    class="border-grey-light text-xs justify-center border hover:bg-gray-100 p-2">
                                                    <div class="flex space-x-10">
                                                        <div>
                                                            <img alt="..." :src="it.product.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'"
                                                                class="h-16 w-16 rounded-md mx-auto" />
                                                        </div>
                                                        <div>
                                                            {{ it.product.name }}
                                                        </div>
                                                    </div>
                                                </td>
                                                 <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"><b>{{ it.quantity }}</b></td>
                                                 <td class="border-grey-light text-xs justify-center border hover:bg-gray-100 p-2 truncate">
                                                   
                                                    <div class="grid gap-4 " :class="CheckQteDefecteuseorderexist?'grid-cols-2':'grid-cols-1'">
                                                     <div class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500  tracking-wider"><b>{{$t('ok_good')}}</b></div>
		                                        	 <div v-if="CheckQteDefecteuseorderexist" class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500  tracking-wider"><b>{{$t('defective')}}</b></div>
                                                     <input v-model="it.product.qteChange" :disabled="CheckQteDefecteuseorderexist"  min="0" :max="it.quantity" type="number" class="border border-green-600  py-2 text-xs px-2 outline-none block h-full w-full">
                                                     <div v-if="CheckQteDefecteuseorderexist" class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"><b>{{ it.quantity-it.product.qteChange }}</b></div>  
                                                    </div>
                                                 </td> 
                                                
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
          <!--*******************  / Order refund ***************************************-->
         <!--*******************  Product returned ***************************************-->
         <span class="flex w-full px-6" :id="'return-'+item._id">
        <div  v-if="item.status=='return'" class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
            <div class="flex w-full mt-1">
                <span class="rounded-full h-12 w-12 bg-gray-200">
                    <i class="material-icons p-3 text-green-400 ">local_mall</i>
                </span>
                <div class="ml-5 w-full pb-4">
                    <div class="text-base mt-1 font-semibold text-gray-600 pb-10">{{$t('products_returned')}}</div>
                    <div class="w-full space-y-4">
                        <div class="w-full">
                            <table class="min-w-full w-full">
                                <thead class="text-white">
                                    <tr class="bg-green-400  flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                        <th class="p-2 justify-center">{{$t('image')}}</th>
                                        <th class="p-2 justify-center">{{$t('name')}}</th>
                                        <th class="p-2 justify-center">{{$t('quantity_sent')}}</th>
                                        <th class="p-2 justify-center">{{$t('quantity_returned')}}</th>
                                    </tr>
                                </thead>
                                <tbody class="flex-1 sm:flex-none">
                                    <tr v-for="(it,index) in item.order.details" :key="index" class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                                        <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2">
                                            <div> <img :alt="it.product.name" :src="it.product.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="h-16 w-16 rounded-md mx-auto" /> </div>
                                        </td>
                                        <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"><b>{{ it.product.name }}</b></td>
                                        <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                <span>{{it.quantity}}</span>
                                        </td>
                                        <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                <table class="min-w-full">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500  tracking-wider">{{$t('ok_good')}}</th>
                                                        <th  v-if="CheckQteReturnedexist" class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500  tracking-wider">{{$t('defective')}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white">
                                                    <tr>
                                                        <td class="px-6 py-4 border whitespace-no-wrap"><div class="flex items-center">
                                                            <input id="Defective" :disabled="CheckQteReturnedexist" type="number" :max="it.quantity" min="0"  v-model="it.product.qteChange" class="py-2 border-4 border-light-blue-500 border-opacity-100  text-xs px-2 outline-none block h-full w-full" />
                                                        </div>
                                                        </td>
                                                        <td  v-if="CheckQteReturnedexist" class="px-6 py-4 border whitespace-no-wrap"><div class="flex items-center"> {{ it.quantity-it.product.qteChange }}</div></td>    
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </span>
        <!--  end product returned -->    
          <!--*******************  Product change ***************************************-->
           <div  v-if="item.type=='change'" class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
                <div class="flex w-full mt-1">
                    <span class="rounded-full h-12 w-12 bg-gray-200">
                        <i class="material-icons p-3 text-green-400 ">local_mall</i>
                    </span>
                    <div class="ml-5 w-full pb-4">
                        <div class="text-base mt-1 font-semibold text-gray-600 pb-10">{{$t('products_change')}}</div>
                        <div class="w-full space-y-4">
                            <div class="w-full">
                                <table class="min-w-full w-full">
                                    <thead class="text-white">
                                        <tr class="bg-green-400  flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                            <th class="p-2 justify-center">{{$t('image')}}</th>
                                            <th class="p-2 justify-center">{{$t('name')}}</th>
                                            <th class="p-2 justify-center">{{$t('quantity')}}</th>
                                            <th class="p-2 justify-center">{{$t('quantity_returned')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="flex-1 sm:flex-none">
                                        <tr v-for="(it,index) in item.order.details" :key="index" class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                                            <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2">
                                                <div> <img :alt="it.product.name" :src="it.product.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="h-16 w-16 rounded-md mx-auto" /> </div>
                                            </td>
                                            <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"><b>{{ it.product.name }}</b></td>
                                            <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"><b>{{ it.quantity }}</b></td>
                                            <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                 <table class="min-w-full">
                                                   <thead>
                                                       <tr>
                                                         <th class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500  tracking-wider">{{$t('ok_good')}}</th>
                                                         <th  v-if="CheckQteDefecteuseexist" class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500  tracking-wider">{{$t('defective')}}</th>
                                                       </tr>
                                                    </thead>
                                                    <tbody class="bg-white">
                                                        <tr>
                                                          <td class="px-6 py-4 border whitespace-no-wrap"><div class="flex items-center">
                                                             <input id="Defective" :disabled="CheckQteDefecteuseexist" type="number" :max="it.quantity" min="0"  v-model="it.product.qteChange" class="py-2 border-4 border-light-blue-500 border-opacity-100  text-xs px-2 outline-none block h-full w-full" />
                                                          </div>
                                                          </td>
                                                           <td  v-if="CheckQteDefecteuseexist" class="px-6 py-4 border whitespace-no-wrap"><div class="flex items-center"> {{ it.quantity-it.product.qteChange }}</div></td>    
                                                       </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        <!--  end product change -->    
    </div>     
       
</template>
<script>
export default {
    props: {
        config: { required: true, type: Object},
        item:{ default: [] },
        index:{ type: Number },
        idWharhouse:{ type: Object },
        currentUser:{ type: Object },
        statusShipp:{ type: Array }, 
        couriers:{ type: Array }, 
        typesShipp:{ type: Array }, 
        feesShipp:{ type: Number },
        zones:{ type: Array },  
        dataCountry: { type: Array }, 
        CheckQteDefecteuseexist:{required:false},  
        CheckQteReturnedexist:{required:false},
        CheckQteDefecteuseorderexist:{required:false},  
    },
    data() {
        return {
           courier :[],
           datatatus:this.statusShipp
        }
    },
    async mounted() {
    //    await this.getCurrentCourierZone(this.item.zone._id);
       let dataWhrahouseSetting=null;
       if(this.item.seller.settings && this.item.seller.settings.length>0) dataWhrahouseSetting=await this.getElementInArray(this.item.seller.settings,this.idWharhouse._id);
       if(!dataWhrahouseSetting || (dataWhrahouseSetting && dataWhrahouseSetting.shipping)) {
           if(this.currentUser.type=="StockManager") this.datatatus=["to prepare","outofstock","prepared",'refused','cancelled','return']
           if(this.currentUser.type=="Livreur") this.datatatus=["prepared",'to pick',"picked",'refused','cancelled']
       }else{
           if(this.currentUser.type=="StockManager") this.datatatus=["to prepare","outofstock","prepared",'refused','cancelled','return']
           if(this.currentUser.type=="Livreur") this.datatatus=["prepared",'shipped',"delivered",'refused','cancelled']
       }
       if(this.currentUser.type=="Accountant") this.datatatus=["delivered",'paid']
       if(this.currentUser.type == "Return") this.datatatus=["cancelled","refused","return",'shipped']
       await this.CheckQteDefecteuseOrder();
       await this.CheckQteDefecteuse();
       await this.CheckQteReturned();
    },
    methods: {
    setSelected(courier) {
        console.log(courier)  
    },
    scrollTo(hash) {
       location.hash = "#return-" + hash;
    },
    onChangeStatus(event){
        if(event.target.value === 'return'){
             this.scrollTo(this.item._id);
        }
      },   
    async getElementInArray(data,value){
        if(value){
          var result  = data.filter(function(o){
                let whar=o.warehouse;
                if(o.warehouse._id) whar=o.warehouse._id;
                return whar == value.toString();
            } );
            return result? result[0] : null; // or undefined
        }else  return null;
        
    },
    searchCouriers: function (value,event) {
        this.$emit('searchCouriers',value,event);
    },
    ChangeQuantityOrder: function (data) {
        this.$emit('ChangeQuantityOrder',data);
    },
    async getCurrentCourierZone(id){
        const filter = {
            type: 'Courier',
            limit:10,
            zones:id    
        };
        const res = await this.$server.search("users",filter);
        if (res.content.results) {
            this.courier = res.content.results;
        } else this.courier = [];
     },   
     async getCourierZone(zone){
         console.log('zone_____',zone)
         this.item.courier.fullName="";
        // console.log(event.target.options[event.target.options.selectedIndex].value)
        const filter = {
            type: 'Courier',
            limit:10,
            zones:zone._id
        };
        const res = await this.$server.search("users",filter);
        if (res.content.results) {
            this.courier = res.content.results;
        } else this.courier = [];
     },
     CheckQteDefecteuse: function (val) {
        this.$emit('CheckQteDefecteuse',this.item);
     },
     CheckQteReturned: function (val) {
        this.$emit('CheckQteReturned',this.item);
     },
    OpenClosePopupModal: function (val,method) {
        this.$emit('OpenClosePopupModalReporte',val,method,this.item._id,this.index);
     },
     CheckQteDefecteuseOrder: function () {
        this.$emit('CheckQteDefecteuseOrder',this.item);
     },
     colorStatus(status) {
            switch (status) {
                case 'to prepare':
                    return "color: rgb(233 147 19); border-color: rgb(233 147 19);" 
                    break;
                 case 'outofstock':
                                    return "color: rgb(203 21 160); border-color: rgb(203 21 160);"
                                    break;
                case 'prepared':
                    return  "color:rgb(58 217 18); border-color: rgb(58 217 18);" 
                    break;
                case 'shipped':
                    return  "color:rgb(58 217 18); border-color: rgb(58 217 18);" 
                    break;
                case 'delivered':
                    return  "color:rgb(58 217 18); border-color: rgb(58 217 18);" 
                    break;
                case 'paid':
                    return  "color:rgb(58 217 18); border-color: rgb(58 220 18);" 
                    break;
                case 'cancelled':
                    return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);" 
                    break;
                case 'refused':
                    return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);" 
                    break;
                case 'remind':
                    return "color: rgb(227 129 210); border-color: rgb(227 129 210);"
                    sbreak;    
                case 'unreachable':
                    return "color: rgb(141 8 118); border-color: rgb(141 8 118);"
                    break;
                 case 'rembourser':
                            return "color: rgb(227 129 210); border-color: rgb(227 129 210);"
                            break;    
                case 'return':
                    return "color: rgb(141 8 118); border-color: rgb(141 8 118);"
                    break;    
    }
        },
    },
    computed: {
      formattedDate() {
        return this.$moment(this.item.date).format('yyyy-MM-DD');
      }, 
       formattedDateShipp() {
        return this.$moment(this.item.date).format('yyyy-MM-DD');
      },     
    },

}
</script>
<style scoped>
.tab-cc{
    margin-bottom: 40px;
    margin-top: 10px;
}
.h-30{
    height: 30px;
}
svg {
  display: inline-block;
  vertical-align: middle;
}
</style>